import React from 'react';

function Footer() {
  return (
    <footer className="footer mt-auto pb-2 pt-3 bg-light">
      <p className="text-center">© 2021 Levi Kane </p>
    </footer>
  );
}
export default Footer;
